<template>
  <div class="deals">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- add dialog for deals -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure want to delete <b>{{ delDeals.name }}</b> form deals
          list?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dodelDeals()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Deals
          <!-- {{ addDeals.name }} -->
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      v-model="addDeals.name"
                      label="Name*"
                      required
                      :rules="rules.name"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      label="Campaign*"
                      required
                      :rules="rules.campaignId"
                      v-model="campaignJSON"
                      :items="campaignList"
                      :search-input.sync="searchCampaign"
                      item-value="name"
                      item-text="name"
                      :loading="isLoading"
                      prepend-icon="mdi-database-search"
                      return-object
                    >
                    </v-autocomplete>
                  </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.dealStartTime"
                        :max="addDeals.dealEndTime"
                        label="From*"
                        v-model="addDeals.dealStartTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!addDeals.dealStartTime && fromErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        From required
                      </p>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.dealEndTime"
                        :max="addDeals.dealStartTime"
                        label="To*"
                        v-model="addDeals.dealEndTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!addDeals.dealEndTime && toErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        To required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addDeals.description"
                      label="Description"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addDeals.url"
                      label="URL"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-file-input
                      required
                      :rules="rules.img"
                      label="Image*"
                      v-model="addDeals.img"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="addDeals.type"
                      :items="type"
                      item-value="value"
                      item-text="text"
                      label="Type"
                    >
                    </v-select>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doaddDeals()">Submit</v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog for deals -->
    <v-dialog v-model="editDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Deals
          <!-- {{ editDeals.name }} -->
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      v-model="editDeals.name"
                      label="Name*"
                      required
                      :rules="rules.name"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      label="Campaign*"
                      required
                      :rules="rules.campaignId"
                      v-model="campaignJSON"
                      :items="campaignList"
                      :search-input.sync="searchCampaign"
                      item-value="name"
                      item-text="name"
                      :loading="isLoading"
                      prepend-icon="mdi-database-search"
                      return-object
                    >
                    </v-autocomplete>
                    <!-- <v-text-field
                      v-model="editDeals.campaignId"
                      label="Campaign*"
                      required
                      :rules="rules.campaignId"
                    ></v-text-field> -->
                  </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.dealStartTime"
                        label="From*"
                        v-model="editDeals.dealStartTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!editDeals.dealStartTime && fromErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        From required
                      </p>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.dealEndTime"
                        label="To*"
                        v-model="editDeals.dealEndTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!editDeals.dealEndTime && toErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        To required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editDeals.description"
                      label="Description"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editDeals.url"
                      label="URL"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-col style="display: flex; flex-flow: column-reverse">
                      <v-card
                        style="width: 20%"
                        v-if="image && image.endsWith('.png')"
                      >
                        <img :src="image" style="width: 100%" />
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="deleteImage()">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                      <p
                        class="no-img"
                        v-if="!image || (image && !image.endsWith('.png'))"
                      >
                        No image has been uploaded
                      </p>
                      <v-file-input
                        required
                        :rules="rules.img"
                        label="Image*"
                        v-model="editDealImg"
                        clearable
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="editDeals.type"
                      :items="type"
                      item-value="value"
                      item-text="text"
                      label="Type"
                    ></v-select>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doEditDeals()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filter search -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDeals()"
          v-model="dealsSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDeals()"
          @keyup.enter.native="searchDeals()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <!-- deals data listing table -->
    <v-data-table
      :headers="headers"
      :items="dealsList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>
            {{
              row.item.dealStartTime
                ? convertDateTime(row.item.dealStartTime)
                : ""
            }}
          </td>
          <td>
            {{
              row.item.dealEndTime ? convertDateTime(row.item.dealEndTime) : ""
            }}
          </td>
          <td>{{ row.item.type }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// import uploadImage from "../../../services/image-uploader";
import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";
import uploadSignedImage from "../../../services/aws-upload-image";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);

export default {
  data() {
    return {
      isLoading: false,
      search: null,
      dealsSearch: null,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      addToDateModel: false,
      editToDateModel: false,
      image: null,
      snackbar: false,
      snackMessage: null,
      type: [{ text: "Hot deals", value: "HOT" }],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Start Date", value: "dealStartTime" },
        { text: "End Date", value: "dealEndTime" },
        { text: "Type", value: "type" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      dealsList: [],
      addDeals: {
        name: null,
        campaignId: null,
        dealStartTime: null,
        dealEndTime: null,
        description: null,
        url: null,
        img: null,
        type: null,
      },
      editDeals: {
        id: null,
        name: null,
        campaignId: null,
        dealStartTime: null,
        dealEndTime: null,
        description: null,
        url: null,
        carList: [],
        carModelFrontList: [],
        img: null,
        type: null,
      },
      rules: {
        name: [v => !!v || "Name required"],
        campaignId: [v => !!v || "Campaign required"],
        dealStartTime: [v => !!v || "From required"],
        dealEndTime: [v => !!v || "To required"],
        img: [v => !!v || "File required"],
      },
      toErrorMsg: false,
      fromErrorMsg: false,
      campaignList: [],
      campaignJSON: null,
      editDealImg: null,
      searchCampaign: null,
      delDeals: {
        id: "",
        name: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchDealsList();
    this.getCampiagn();
  },
  watch: {
    searchCampaign(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      this.getCampiagn(val);
    },
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchDealsList();
    },
    getCampiagn(val) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/campaignData`,
        {
          searchContent: val,
          // status: "APPROVED",
        },
        this.token
      )
        .then(res => {
          if (res.data) {
            this.campaignList = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    deleteImage() {
      this.image = null;
      this.editDealImg = null;
      this.editDeals.img = null;
    },
    convertDateTime(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD HH:mm");
      return parsedDate;
    },
    searchDeals() {
      this.fetchDealsList();
    },
    fetchDealsList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealsSearch,
        sort: self.sortValue,
      };
      getAxios(`${process.env.VUE_APP_SERVER_URL}/dealSystem/dealData`, params)
        .then(res => {
          if (res.data) {
            self.dealsList = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealsList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    setEditItemInfo(item) {
      this.editDeals.id = item.id;
      this.editDeals.name = item.name;
      this.editDeals.campaignId = item.campaign.id;
      this.editDeals.dealStartTime = item.dealStartTime
        ? this.convertDateTime(item.dealStartTime)
        : "";
      this.editDeals.dealEndTime = item.dealEndTime
        ? this.convertDateTime(item.dealEndTime)
        : "";
      this.editDeals.description = item.description;
      this.editDeals.url = item.url;
      this.editDeals.type = item.type;
      this.editDeals.img = item.img;
      item.carList.length === 0
        ? []
        : item.carList.map(car => {
            this.editDeals.carList.push(car?.id);
          });
      item.carModelFrontList.length === 0
        ? []
        : item.carModelFrontList.map(car => {
            this.editDeals.carModelFrontList.push(car?.id);
          });
      const imgName = item.img
        ? item.img
            .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
            .slice(-1)[0]
        : null;
      this.campaignJSON = item.campaign;
      this.editDeals.img = imgName ? imgName : null;
      this.editDealImg = imgName ? imgName : null;
      this.image = item.img;
    },
    resetEditItemInfo() {
      this.editDeals.id = null;
      this.editDeals.name = null;
      this.editDeals.campaignId = null;
      this.editDeals.dealStartTime = null;
      this.editDeals.dealEndTime = null;
      this.editDeals.description = null;
      this.editDeals.url = null;
      this.editDeals.carList = [];
      this.editDeals.carModelFrontList = [];
      this.editDeals.img = null;
      this.editDeals.type = null;
      this.image = null;
      this.campaignJSON = null;
    },
    resetAddItemInfo() {
      this.addDeals.name = null;
      this.addDeals.campaignId = null;
      this.addDeals.dealStartTime = null;
      this.addDeals.dealEndTime = null;
      this.addDeals.description = null;
      this.addDeals.url = null;
      this.addDeals.img = null;
      this.addDeals.type = null;
      this.campaignJSON = null;
    },
    async doaddDeals() {
      const self = this;
      try {
        this.fromErrorMsg = self.addDeals.dealStartTime ? false : true;
        this.toErrorMsg = self.addDeals.dealEndTime ? false : true;
        if (
          this.$refs.form.validate() &&
          !this.fromErrorMsg &&
          !this.toErrorMsg
        ) {
          if (self.addDeals.img) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(
              self.addDeals.img,
              uploadUrl.data.originalUrl
            );
            self.addDeals.img = uploadUrl.data.originalName;
          }
          if (self.campaignJSON) {
            self.addDeals.campaignId = self.campaignJSON.id;
          }
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/dealSystem/addDeal`,
            self.addDeals
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.addDialog = false;
                self.fetchDealsList();
                self.resetAddItemInfo();
                this.$refs.form.reset();
                this.snackbar = true;
                this.snackMessage = "Deals added successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async doEditDeals() {
      const self = this;
      try {
        this.fromErrorMsg = self.editDeals.dealStartTime ? false : true;
        this.toErrorMsg = self.editDeals.dealEndTime ? false : true;
        if (
          this.$refs.form.validate() &&
          !this.fromErrorMsg &&
          !this.toErrorMsg
        ) {
          if (self.editDealImg && typeof self.editDealImg === "object") {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(
              self.editDealImg,
              uploadUrl.data.originalUrl
            );
            self.editDeals.img = uploadUrl.data.originalName;
          }
          self.editDeals.dealStartTime = self.editDeals.dealStartTime
            ? new Date(self.editDeals.dealStartTime)
            : "";
          self.editDeals.dealEndTime = self.editDeals.dealEndTime
            ? new Date(self.editDeals.dealEndTime)
            : "";
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/dealSystem/updDeal`,
            self.editDeals
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.editDialog = false;
                self.fetchDealsList();
                self.resetEditItemInfo();
                this.$refs.form.reset();
                this.snackbar = true;
                this.snackMessage = "Deals updated successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delDeals.id = item.id;
      this.delDeals.name = item.name;
      this.deleteDialog = true;
    },
    dodelDeals() {
      const self = this;
      const param = {
        dealId: self.delDeals.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealSystem/delDeal`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchDealsList();
            this.snackbar = true;
            this.snackMessage = "Deal deleted successfully";
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
  },
};
</script>
<style>
.no-img {
  color: gray;
  font-size: small;
}
.v-application .error--text {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-messages__message {
  color: red !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
</style>
